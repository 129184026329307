<template>
  <ModalMain v-if="promoModalData" @close="handleClose">
    <div class="w-full flex flex-col">
      <img
        :src="$sanityImgUrl(promoModalData['modalBanner'].asset._ref)"
        :class="[isMobileDevice ? 'aspect-mobile' : null]"
        class="image aspect-[4/3] mt-8"
      >
      <div v-if="description" class="p-8 w-full h-[350px] overflow-y-scroll aspect-1" v-html="description"/>
      <ButtonButton size="sm" class="m-8 mb-0" @click.prevent="redirect">
        {{ promoModalData.modalButton ?? promoModalData.linkTo }}
      </ButtonButton>
    </div>
  </ModalMain>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';

import { useDeviceStore } from '~/store/device';
import { useUiStore } from '~/store/ui';

const { $sanityImgUrl, $sanityBlocksToHtml, } = useNuxtApp();
const emit = defineEmits(['close',]);
const router = useRouter();

const deviceStore = useDeviceStore();
const { isMobileDevice, } = storeToRefs(deviceStore);

const uiStore = useUiStore();
const { closePromoModal, } = uiStore;
const { promoModalData, } = storeToRefs(uiStore);

const description = $sanityBlocksToHtml(promoModalData.value.description);

function handleClose() {
  emit('close');
  closePromoModal();
}

function redirect() {
  handleClose();
  router.push(promoModalData.value.linkTo);
}
</script>
